/* eslint-disable @nx/enforce-module-boundaries */
import { isInitializing as isInitializingSelector } from '@va/dashboard/selectors/app';
import { isDataResetRequested, isWebsiteInProgress } from '@va/dashboard/selectors/core';
import { FeatureCounterContextProvider } from '@va/dashboard/shared/feature-counter';
import {
  LayoutProps,
  LayoutStickyHeaderWrapper,
  getLayoutClassName,
  useScrollToTopEffect,
} from '@va/dashboard/shared/ui-layout';
import { LoaderBar } from '@va/ui/design-system';

import { PropsWithChildren, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { AnnouncementBanner } from '@va/dashboard/components';
import { isTabletDevice } from '@va/dashboard/selectors/ui';
import { ResetRequestedPage } from '@va/dashboard/ui/pages';
import TooltipManager from '@va/deprecated/components/tooltips/TooltipManager';
import { isAccountSettingsPageSelected } from '@va/standalone/shared/helpers';
import { PageHeaderContextProvider, RefreshContextProvider } from '@va/util/components';
import NotificationSystemWrapper from 'common/components/misc/NotificationSystemWrapper';
import { useLocation } from 'react-router';
import WbpModals from '../modals/WbpModals';
import { LayoutPreHeader } from './LayoutPreHeader';
import { SidebarWrapper } from './SidebarWrapper';
import TopBar from './TopBar';

export default function Layout({ children, hasBackButton = false }: PropsWithChildren<LayoutProps>) {
  useScrollToTopEffect();

  const { pathname } = useLocation();

  const isInitializing = useSelector(isInitializingSelector);
  const websiteInProgress = useSelector(isWebsiteInProgress);

  const resetRequested = useSelector(isDataResetRequested);
  const isTablet = useSelector(isTabletDevice);

  const showSidebar = isTablet || !isAccountSettingsPageSelected(pathname);

  const contentClassName = useMemo(() => getLayoutClassName(showSidebar), [showSidebar]);

  if (isInitializing || websiteInProgress) return <LoaderBar />;

  if (resetRequested) return <ResetRequestedPage />;

  return (
    <>
      <TopBar hasBackButton={hasBackButton} />
      <FeatureCounterContextProvider>
        <>
          <SidebarWrapper />
          <RefreshContextProvider>
            <PageHeaderContextProvider>
              <div className='flex relative pt-[70px] min-h-full'>
                <div className={contentClassName}>
                  <LayoutStickyHeaderWrapper />
                  {children}
                  <NotificationSystemWrapper />
                  <TooltipManager />
                  <AnnouncementBanner />
                  <LayoutPreHeader />
                  <WbpModals />
                </div>
              </div>
            </PageHeaderContextProvider>
          </RefreshContextProvider>
        </>
      </FeatureCounterContextProvider>
    </>
  );
}
