import * as ReactDOM from 'react-dom/client';
import 'react-loading-skeleton/dist/skeleton.css';
import './index.css';

import { logVersion, setDefaultTheme } from '@va/util/helpers';
import App from './app/app';

logVersion();
setDefaultTheme();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);
