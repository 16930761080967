import { nonTranslatableText } from '@va/constants';
import { requestTranslations, setCustomizationOptions, setLocale, setWindowWidth } from '@va/dashboard/actions/ui';
import { ChatwootWidget, NotificationV2 } from '@va/dashboard/components';
import { DashboardGlobalFiltersConfigProvider } from '@va/dashboard/shared/filters';
import { Config as BaseConfig } from '@va/http-client';
import { TranslationsProvider, getTranslationsUrl, useLocalizationContext } from '@va/localization';
import { RemoveAdblockerPage } from '@va/shared/remove-adblocker-page';
import { ConfirmationDialogContextProvider } from '@va/shared/util-confirmation';
import { createStore } from '@va/store';
import { LoaderBar } from '@va/ui/design-system';
import { ErrorBoundary } from '@va/util/components';
import { debounce, extractLanguage } from '@va/util/helpers';
import Config from 'Config';
import moment from 'moment';
import { PropsWithChildren, StrictMode } from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { SWRConfig } from 'swr';
import * as Actions from './actions';
import rootReducer from './reducers';
import { history } from './router/history';
import Routes from './router/routes';
import rootSaga from './sagas';

const locale = extractLanguage();

moment.locale(locale);

const getAuthenticateHeaders = (store: any) => () => {
  const state = store.getState();
  const auth = state.getIn(['core', 'authentication']).toJS();
  const headers: Record<string, unknown> = {};

  if (auth.token) {
    headers['Authorization'] = 'Bearer ' + auth.token;
  }

  return headers;
};

const store = createStore(rootReducer, rootSaga);

window.addEventListener(
  'resize',
  debounce(() => {
    store.dispatch(setWindowWidth(window.innerWidth));
  }, 150),
);
BaseConfig.setAuthProvider(getAuthenticateHeaders(store) as any);
BaseConfig.setApiBaseUrl(Config.apiUrl);
store.dispatch(Actions.initialize());
store.dispatch(requestTranslations(locale, true));
store.dispatch(setWindowWidth(window.innerWidth));
store.dispatch(
  setCustomizationOptions({
    providerName: nonTranslatableText.appName,
    supportCenterDomain: 'https://www.twipla.com',
    chatwootToken: Config.chatwootToken,
  }),
);

const fetchTranslationsFn = async (locale = 'en') => {
  const response = await fetch(getTranslationsUrl(locale));

  const responseText = await response.text();

  return JSON.parse(responseText.replace(/\\n/g, '<br/>'));
};

const WebsiteViewerApp = () => {
  return (
    <Providers>
      <App />
    </Providers>
  );
};

export default WebsiteViewerApp;

const Providers = ({ children }: PropsWithChildren) => {
  return (
    <StrictMode>
      <SWRConfig value={{ revalidateIfStale: false, revalidateOnFocus: false }}>
        <SkeletonTheme baseColor='#F9F9F9' highlightColor='#EEEEEE'>
          <TranslationsProvider
            onLocaleChange={(locale) => {
              store.dispatch(setLocale(locale));
            }}
            fetchTranslations={fetchTranslationsFn}
          >
            <Provider store={store}>
              <ConfirmationDialogContextProvider>
                <DashboardGlobalFiltersConfigProvider>{children}</DashboardGlobalFiltersConfigProvider>
              </ConfirmationDialogContextProvider>
            </Provider>
          </TranslationsProvider>
        </SkeletonTheme>
      </SWRConfig>
    </StrictMode>
  );
};

const App = () => {
  const { isLoading: translationsLoading, translationsError } = useLocalizationContext();
  if (translationsLoading) return <LoaderBar />;
  if (translationsError) return <RemoveAdblockerPage />;

  return (
    <Router history={history}>
      <ErrorBoundary>
        <ChatwootWidget />
        <NotificationV2 />
        <Routes />
      </ErrorBoundary>
    </Router>
  );
};
