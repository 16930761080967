/* eslint-disable @nx/enforce-module-boundaries */
import AccountSettingsRequiredModal from '@va/dashboard/shared/modals/AccountSettingsRequiredModal';
import Modals from '@va/dashboard/shared/modals/index';
import UpdateCompanyDetailsModal from '@va/dashboard/shared/modals/UpdateCompanyDetailsModal';

import CheckPasswordModal from 'standalone/components/AccountSettings/check-password/CheckPasswordModal';
import CancelSubscription from 'standalone/components/modals/CancelSubscription';
import CreateEventTag from 'standalone/components/modals/create-events-tag/CreateEventTag';
import DeleteSubscriptionModal from 'standalone/components/modals/DeleteSubscription';
import ReactivateSubscription from 'standalone/components/modals/ReactivateSubscription';
import UpgradeModal from 'standalone/components/modals/UpgradeModal';
import { useTranslate } from '@va/localization';

export default function WbpModals() {
  const translate = useTranslate();
  return (
    <>
      <Modals.SignDPA dpaLink={translate('all.privacyCenter.legalDocuments.dpaLink')} />
      <AccountSettingsRequiredModal />
      <Modals.AnnouncementModal />
      <Modals.SsrTechnicalLimitation />
      <Modals.SsrReachedLimit />
      <Modals.ManualScreenshotContainer />
      <UpdateCompanyDetailsModal />
      <Modals.VisitsLimitReached />
      <Modals.VideoOverlay />
      <Modals.TabInformation />
      <Modals.FeatureLimitReached />
      <CreateEventTag />
      <CheckPasswordModal />
      <CancelSubscription />
      <DeleteSubscriptionModal />
      <ReactivateSubscription />
      <UpgradeModal />
    </>
  );
}
