import { SOURCE_TAB } from '@va/constants';
import { LoadingLogo } from '@va/ui/design-system';
import { lazy, Suspense } from 'react';
import { Route as RouterRoute, Switch } from 'react-router-dom';
import Layout from '../components/layout/Layout';

const PagesTab = lazy(() => import('@va/dashboard/tabs/pages'));
const CampaignsTab = lazy(() => import('@va/dashboard/tabs/campaigns'));

const Visitors = lazy(() => import('@va/dashboard/tabs/visitors'));

const OverviewTab = lazy(() => import('@va/dashboard/tabs/overview'));
const RedirectToOverview = lazy(() => import('@va/dashboard/tabs/overview/redirect'));

// eslint-disable-next-line @nx/enforce-module-boundaries
const Invoice = lazy(() => import('standalone/components/Invoice'));
const TokenExpiredPage = lazy(() => import('plugin/components/pages/TokenExpired'));
const GeneralErrorPage = lazy(() => import('@va/dashboard/tabs/error'));

const VisitorsHistoryV2 = lazy(() => import('@va/dashboard/tabs/visitors/history'));

const DevicesTab = lazy(() => import('@va/dashboard/tabs/devices'));
const CompetitionTab = lazy(() => import('@va/dashboard/tabs/competition'));

const MyCampaignDetails = lazy(() => import('@va/dashboard/tabs/campaigns/details'));

const TrafficDetailsPage = lazy(() => import('@va/dashboard/tabs/traffic'));

// eslint-disable-next-line @nx/enforce-module-boundaries
const GeneralSettingsTab = lazy(() => import('standalone/components/tabs/general-settings/GeneralSettingsTab'));
const ModulesSettingsPage = lazy(() => import('@va/dashboard/tabs/module-settings'));
const SubscriptionSettingsTabV2 = lazy(() => import('@va/dashboard/tabs/subscription-settings'));
const PageSettings = lazy(() => import('@va/dashboard/tabs/page-settings'));
const PrivacyCenterTab = lazy(() => import('@va/dashboard/tabs/privacy-center'));
const VisitorSegments = lazy(() => import('@va/dashboard/feature/visitor-segments'));
const ConsentSettingsTab = lazy(() => import('@va/dashboard/tabs/consent'));
const RecordingsDetailsPageV2 = lazy(() => import('@va/dashboard/tabs/recordings/details'));

const EventTrackingModule = lazy(() => import('@va/dashboard/tabs/event-tracking/module'));
const VisitorRecordingsTabV2 = lazy(() => import('@va/dashboard/tabs/recordings'));
const VisitorRecordingsSettingsOnboarding = lazy(() => import('@va/dashboard/tabs/recordings/onboardings'));

/** HEATMAPS */
const HeatmapDetailView = lazy(() => import('@va/dashboard/tabs/heatmaps/details'));
const HeatmapsTab = lazy(() => import('@va/dashboard/tabs/heatmaps'));
const CreateHeatmapPage = lazy(() => import('@va/dashboard/tabs/heatmaps/create'));
/** HEATMAPS */

/**** FUNNELS ****/
const CreateFunnelPage = lazy(() => import('@va/dashboard/tabs/funnels/create'));
const EditFunnelPage = lazy(() => import('@va/dashboard/tabs/funnels/edit'));
const FunnelsTab = lazy(() => import('@va/dashboard/tabs/funnels/main'));
const FunnelDetailsPage = lazy(() => import('@va/dashboard/tabs/funnels/details'));
/**** FUNNELS ****/

const ExportTab = lazy(() => import('@va/dashboard/tabs/export'));
const LatestVisitorsOverviewV2 = lazy(() => import('@va/dashboard/tabs/visitors/overview'));

/**** POLLS ****/
const PollsTab = lazy(() => import('@va/dashboard/tabs/feedback/polls/main'));
const CreatePollPage = lazy(() => import('@va/dashboard/tabs/feedback/polls/create'));
const EditPollPage = lazy(() => import('@va/dashboard/tabs/feedback/polls/edit'));
const PollDetailsPage = lazy(() => import('@va/dashboard/tabs/feedback/polls/details'));
const PollParticipantsResponsesPage = lazy(() => import('@va/dashboard/tabs/feedback/polls/participants'));
/**** POLLS ****/

/**** SURVEYS ****/
const SurveysTab = lazy(() => import('@va/dashboard/tabs/feedback/surveys/main'));
const CreateSurveyPage = lazy(() => import('@va/dashboard/tabs/feedback/surveys/create'));
const EditSurveyPage = lazy(() => import('@va/dashboard/tabs/feedback/surveys/edit'));
const SurveyDetailsPage = lazy(() => import('@va/dashboard/tabs/feedback/surveys/details'));
/**** SURVEYS ****/

const WebsiteRoute = ({ component: Component, hasBackButton, extraProps, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={(props: any) => (
        <Layout hasBackButton={hasBackButton}>
          <Suspense fallback={<div />}>
            {/* TODO Add wixModals */}
            <Component {...props} {...extraProps} />
          </Suspense>
        </Layout>
      )}
    />
  );
};

const Route = (props: any) => {
  return (
    <Suspense fallback={<LoadingLogo />}>
      <RouterRoute {...props} />
    </Suspense>
  );
};

const AppRoutes = () => {
  return (
    <Switch>
      <Route path='/expired-token' component={TokenExpiredPage} />
      <Route path='/invoice/:invoiceKey' component={Invoice} />

      <WebsiteRoute exact path='/' component={RedirectToOverview} />
      <WebsiteRoute path='/dashboard' component={OverviewTab} />

      <WebsiteRoute exact path='/statistics/overview' component={LatestVisitorsOverviewV2} />
      <WebsiteRoute
        exact
        path='/visitors/history/:sessionKey'
        component={VisitorsHistoryV2}
        extraProps={{ sourceTab: SOURCE_TAB.LATEST_VISITORS }}
      />

      <WebsiteRoute path='/visitors' component={Visitors} />
      <WebsiteRoute path='/pages' component={PagesTab} />
      <WebsiteRoute exact path='/devices' component={DevicesTab} />
      <WebsiteRoute exact path='/competition' component={CompetitionTab} />

      <WebsiteRoute
        exact
        // campaignKey is a MD5 value (32 hexadecimal digits)
        path='/campaigns/:campaignKey([A-Fa-f0-9]{32})'
        component={MyCampaignDetails}
      />
      <WebsiteRoute path='/campaigns' component={CampaignsTab} />
      <WebsiteRoute path='/traffic' component={TrafficDetailsPage} />

      <WebsiteRoute exact path='/settings' component={GeneralSettingsTab} />
      <WebsiteRoute path='/settings/modules' component={ModulesSettingsPage} />
      <WebsiteRoute exact path='/settings/subscription' component={SubscriptionSettingsTabV2} />
      <WebsiteRoute exact path='/settings/pages' component={PageSettings} />

      <WebsiteRoute exact path='/settings/privacy-center' component={PrivacyCenterTab} />

      <WebsiteRoute path='/settings/visitor-segments' component={VisitorSegments} hasBackButton />

      <WebsiteRoute exact path='/settings/consent' component={ConsentSettingsTab} />

      <WebsiteRoute path='/behaviour/event-tracking' component={EventTrackingModule} />
      <WebsiteRoute exact path='/behaviour/recordings-onboarding' component={VisitorRecordingsSettingsOnboarding} />
      <WebsiteRoute exact path='/behaviour/recordings' component={VisitorRecordingsTabV2} />
      <WebsiteRoute exact path='/behaviour/recordings/:sessionId' component={RecordingsDetailsPageV2} />

      <WebsiteRoute exact path='/behaviour/funnels/create' component={CreateFunnelPage} />
      <WebsiteRoute exact path='/behaviour/funnels/edit/:funnelId' component={EditFunnelPage} />
      <WebsiteRoute exact path='/behaviour/funnels/:funnelId([A-Za-z0-9]{14}.[0-9]{8})' component={FunnelDetailsPage} />
      <WebsiteRoute path='/behaviour/funnels' component={FunnelsTab} />

      {/* Feedback/Polls */}
      <WebsiteRoute exact path='/feedback/polls' component={PollsTab} />
      <WebsiteRoute exact path='/feedback/polls/create' component={CreatePollPage} />
      <WebsiteRoute exact path='/feedback/polls/:id/edit' component={EditPollPage} />
      <WebsiteRoute
        exact
        path='/feedback/polls/:id/visitors/:visitorKey/responses'
        component={PollParticipantsResponsesPage}
      />
      <WebsiteRoute path='/feedback/polls/:id' component={PollDetailsPage} />

      {/* Feedback/Surveys */}
      <WebsiteRoute exact path='/feedback/surveys' component={SurveysTab} />
      <WebsiteRoute exact path='/feedback/surveys/create' component={CreateSurveyPage} />
      <WebsiteRoute exact path='/feedback/surveys/:id/edit' component={EditSurveyPage} />
      <WebsiteRoute path='/feedback/surveys/:id' component={SurveyDetailsPage} />

      <WebsiteRoute
        // 24-character hexadecimal string value
        path='/behaviour/heatmaps/:heatmapId([A-Fa-f0-9]{24})'
        component={HeatmapDetailView}
        hasBackButton
      />

      <WebsiteRoute path='/behaviour/heatmaps/create' component={CreateHeatmapPage} />

      <WebsiteRoute path='/behaviour/heatmaps' component={HeatmapsTab} />
      <WebsiteRoute exact path='/export' component={ExportTab} />

      <Route component={GeneralErrorPage} />
    </Switch>
  );
};

export default AppRoutes;
