import {
  getWebsites,
  initializeSucceeded,
  SET_ACTIVE_WEBSITE_SUCCEEDED,
  setActiveWebsite,
} from '@va/standalone/shared/actions';
import { addCredentialsToLocalStorage } from '@va/standalone/shared/helpers';
import { getParameterByName } from '@va/util/helpers';
import { redirectToAnotherPage } from 'plugin/actions';
import { INITIALIZE, REDIRECT_TO_ANOTHER_PAGE } from 'plugin/actions/types';
import { notAuthRoutes } from 'plugin/constants';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { watchersArr as standaloneWatchers } from 'standalone/sagas';

import {
  AUTHENTICATE_SUCCEEDED,
  authenticateFailed,
  authenticateSucceeded,
  getAccountInformation,
  getAnnouncements,
} from '@va/dashboard/actions/api';
import { handleUpdateCompanyDetailsModal, setInstanceId } from '@va/dashboard/actions/app';
import { watchers as coreWatchers } from '@va/dashboard/sagas/shared';
import { all, getContext, put, spawn, take, takeLatest } from 'redux-saga/effects';

export const watchersArr = [
  takeLatest(INITIALIZE, initialize),
  takeLatest(REDIRECT_TO_ANOTHER_PAGE, redirect),
  ...coreWatchers,
  ...standaloneWatchers,
];

function* redirect(action) {
  const history = yield getContext('history');
  history.push(action.url);
}

export default function* rootSaga() {
  yield all(watchersArr);
}

function* initialize() {
  yield spawn(initializeAuth);
  const websiteKey = getParameterByName('wid');
  yield take([AUTHENTICATE_SUCCEEDED]);
  yield put(getWebsites());
  yield put(getAccountInformation());
  yield put(setActiveWebsite(websiteKey));
  yield take([SET_ACTIVE_WEBSITE_SUCCEEDED]);
  yield put(getAnnouncements());
  yield put(handleUpdateCompanyDetailsModal());
  yield put(initializeSucceeded());
}

function* initializeAuth() {
  if (notAuthRoutes.includes(window.location.pathname) || window.location.pathname.includes('/invoice')) {
    return;
  }

  const token = getParameterByName('token');
  const websiteKey = getParameterByName('wid');
  const userId = getParameterByName('uid');
  if (!token || !websiteKey || !userId) {
    yield put(redirectToAnotherPage('/error'));
    yield put(authenticateFailed());
  } else {
    const credentials = {
      token: token,
      userId: userId,
      instanceId: websiteKey,
      lastWebsiteId: websiteKey,
    };
    yield put(setInstanceId(websiteKey));
    addCredentialsToLocalStorage(credentials);
    yield put(authenticateSucceeded(credentials));
  }
}
