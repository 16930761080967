import { Heading1 } from '@va/ui/design-system';
import { useLocale } from '@va/localization';
import { useMemo } from 'react';
import { is3asApp } from '@va/util/helpers';
import { SupportedLanguage, translations } from './adblockerPageTranslations';

const textToHTML = (text: string) => {
  const regex = /\[support](.*)\[\/support]/;

  return text.replace(regex, (substring, args) => {
    if (is3asApp()) return args;
    return `<a class="text-primary font-semibold" href="mailto:support@twipla.com?subject=Contact from Remove Adblocker page">${args}</a>`;
  });
};

export function RemoveAdblockerPage() {
  const { locale } = useLocale();
  const translated = useMemo(() => translations[locale as SupportedLanguage], [locale]);

  return (
    <div className='fixed w-full h-full flex items-center justify-center text-center'>
      <div className='p-12px sm:max-w-full max-w-1/2'>
        <Heading1>{translated?.title}</Heading1>
        <br />
        <p
          className='font-normal leading-relaxed'
          dangerouslySetInnerHTML={{ __html: textToHTML(translated.text) }}
        ></p>
      </div>
    </div>
  );
}
