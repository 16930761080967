export type SupportedLanguage = 'en' | 'de' | 'es' | 'it' | 'pt' | 'ro' | 'fr';
type TranslationContent = {
  title: string;
  text: string;
};
type TranslationsObj = Record<SupportedLanguage, TranslationContent>;

export const translations: TranslationsObj = {
  en: {
    title: 'We are sorry, but an error has occurred!',
    text: 'A reason could be that you have an adblocker activated. If this is the case, please deactivate it and refresh the page. If the platform still does not show, please contact [support]support[/support].',
  },
  de: {
    title: 'Es tut uns leid, aber ein Fehler ist aufgetreten!',
    text: 'Ein Grund könnte sein, dass Sie einen Adblocker aktiviert haben. Wenn dies der Fall ist, deaktivieren Sie ihn bitte und aktualisieren Sie die Seite. Wenn die Plattform immer noch nicht angezeigt wird, wenden Sie sich bitte an den [support]Support[/support].',
  },
  es: {
    title: 'Lo sentimos, pero se ha producido un error.',
    text: 'Una razón podría ser que tienes un adblocker activado. Si es así, desactívalo y actualiza la página. Si la plataforma sigue sin aparecer, ponte en contacto con el [support]servicio de asistencia[/support].',
  },
  it: {
    title: 'Siamo spiacenti, ma si è verificato un errore!',
    text: "Il motivo potrebbe essere l'attivazione di un adblocker. In tal caso, disattivarlo e aggiornare la pagina. Se la piattaforma continua a non essere visualizzata, contattare [support]l'assistenza[/support].",
  },
  pt: {
    title: 'Lamentamos, mas ocorreu um erro!',
    text: 'Um motivo pode ser o facto de ter um bloqueador de anúncios ativado. Se for esse o caso, desactive-o e actualize a página. Se a plataforma continuar a não aparecer, contacte a [support]assistência[/support].',
  },
  ro: {
    title: 'Ne pare rău, dar a apărut o eroare!',
    text: 'Un motiv ar putea fi faptul că aveți un adblocker activat. În acest caz, vă rugăm să îl dezactivați și să reîmprospătați pagina. Dacă platforma tot nu apare, vă rugăm să contactați [support]serviciul de asistență[/support].',
  },
  fr: {
    title: "Nous sommes désolés, mais une erreur s'est produite!",
    text: "Il se peut que vous ayez activé un bloqueur de publicité. Si c'est le cas, veuillez le désactiver et rafraîchir la page. Si la plateforme ne s'affiche toujours pas, veuillez contacter [support]le service d'assistance[/support].",
  },
};
