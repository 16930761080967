import { isSidebarOpen, isTabletDevice } from '@va/dashboard/selectors/ui';
import { AppVersion, Sidebar, UpgradeAccountNav } from '@va/dashboard/shared/feature-sidebar';
import { navigationActions } from '@va/dashboard/slices';
import { isAccountSettingsPageSelected } from '@va/standalone/shared/helpers';
import { getNavigationTabs } from 'plugin/helpers/menu-navigation-tabs';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

export const SidebarWrapper = () => {
  const isOpen = useSelector(isSidebarOpen);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isTablet = useSelector(isTabletDevice);
  const showSidebar = isTablet || !isAccountSettingsPageSelected(pathname);

  const navigationTabs = useMemo(() => getNavigationTabs(), []);

  if (!showSidebar) return null;

  return (
    <Sidebar
      sidebarItems={navigationTabs as any}
      isSidebarExpand={isOpen}
      onToggleSidebar={() => {
        dispatch(navigationActions.toggleSidebar());
      }}
      footer={() => (
        <div className='flex flex-col items-center gap-3 justify-end h-full pb-3'>
          <UpgradeAccountNav />
          <AppVersion />
        </div>
      )}
      setIsSidebarExpanded={(value) => {
        dispatch(navigationActions.setIsSidebarOpen(value));
      }}
    />
  );
};
