import { GoToWebsiteButton } from '@va/dashboard/components';
import { isSidebarOpen } from '@va/dashboard/selectors/ui';
import {
  TOP_BAR_TITLE_WRAPPER_ID,
  TopBarProps,
  UserMenu,
  topBarClassNames,
  useSidebarToggleEffect,
} from '@va/dashboard/shared/ui-layout';
import { navigationActions } from '@va/dashboard/slices';
import { CloseIconWithBorder, HamburgerIcon, LogoApp } from '@va/svg-visa-icons';
import { Link } from '@va/util/router';
import classNames from 'classnames';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function TopBar({ hasBackButton }: TopBarProps) {
  const isLeftNavOpen = useSelector(isSidebarOpen);

  const dispatch = useDispatch();
  const toggleSidebar = useCallback(() => {
    dispatch(navigationActions.toggleSidebar());
  }, [dispatch]);

  useSidebarToggleEffect(hasBackButton, isLeftNavOpen, toggleSidebar);

  return (
    <div id='top-bar' className={classNames(topBarClassNames)}>
      <div className='flex lg:hidden flex-row flex-nowrap items-center overflow-hidden'>
        {!hasBackButton && (
          <div
            id='top-bar-icon-wrapper'
            onClick={toggleSidebar}
            className='w-6 h-6 flex items-center justify-center cursor-pointer'
          >
            {isLeftNavOpen ? <CloseIconWithBorder radius={12} color='#000' /> : <HamburgerIcon />}
          </div>
        )}
        <div id={TOP_BAR_TITLE_WRAPPER_ID} className={classNames('min-w-0', { 'pl-18': !hasBackButton })} />
      </div>
      <Link to={'/'} className='hidden lg:block cursor-pointer'>
        <LogoApp />
      </Link>
      <div className='flex items-center gap-2'>
        <GoToWebsiteButton className='mr-2' />
        <UserMenu />
      </div>
    </div>
  );
}
