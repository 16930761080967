import { combineReducers, coreReducer, customizationReducer } from '@va/dashboard/reducers';
import { reducer as formReducer } from 'redux-form/immutable';
// eslint-disable-next-line @nx/enforce-module-boundaries
import apiReducer from 'standalone/reducers/api';
// eslint-disable-next-line @nx/enforce-module-boundaries
import appReducer from 'standalone/reducers/app';
// eslint-disable-next-line @nx/enforce-module-boundaries
import uiReducer from 'standalone/reducers/ui';

export default combineReducers({
  api: apiReducer,
  app: appReducer,
  ui: uiReducer,
  form: formReducer,
  core: coreReducer,
  customization: customizationReducer,
});
