import { moduleNames, tabNames } from '@va/constants';
import { WithLink, WithSubMenu } from '@va/types/navigation';
import { Link } from '@va/util/router';

export const getNavigationTabs = () => {
  const feedbackTab: WithSubMenu = {
    index: 4,
    name: tabNames.feedback,
    hasSubGroups: true,
    displayInLeftNav: true,
    submenu: [
      {
        name: tabNames.polls,
        linkTo: ({ className }) => <Link className={className} to={'/feedback/polls'} />,
        displayInLeftNav: true,
      },
      {
        name: tabNames.surveys,
        linkTo: ({ className }) => <Link className={className} to={'/feedback/surveys'} />,
        displayInLeftNav: true,
      },
    ],
  };

  const milestoneTab: WithLink = {
    index: 7,
    linkTo: ({ className, translate }) => (
      <a className={className} href={translate('externalLink.tab.milestone')} target='_blank' rel='noreferrer'>
        {''}
      </a>
    ),
    name: tabNames.milestone,
    displayInLeftNav: true,
    openInNewTab: true,
  };

  const settingsSubTabs: WithLink[] = [
    {
      name: tabNames.settings,
      linkTo: ({ className }) => <Link className={className} to={'/settings'} />,
      displayInLeftNav: true,
    },
    {
      name: tabNames.modulesSettings,
      linkTo: ({ className }) => <Link className={className} to={'/settings/modules'} />,
      displayInLeftNav: true,
    },
    {
      name: tabNames.subscriptionSettings,
      linkTo: ({ className }) => <Link className={className} to={'/settings/subscription'} />,
      displayInLeftNav: true,
    },
    {
      name: tabNames.pageSettings,
      linkTo: ({ className }) => <Link className={className} to={'/settings/pages'} />,
      displayInLeftNav: true,
    },
    {
      name: tabNames.consent,
      linkTo: ({ className }) => <Link className={className} to={'/settings/consent'} />,
      displayInLeftNav: true,
    },
    {
      name: tabNames.visitorSegments,
      linkTo: ({ className }) => <Link className={className} to={'/settings/visitor-segments'} />,
      displayInLeftNav: true,
    },
    {
      name: tabNames.export,
      linkTo: ({ className }) => <Link className={className} to={'/export'} />,
      displayInLeftNav: true,
    },
  ];

  const tabs: Array<WithLink | WithSubMenu> = [
    {
      index: 1,
      linkTo: ({ className }) => <Link className={className} to={'/'} />,
      name: tabNames.overview,
      displayInLeftNav: true,
      isNew: true,
    },
    {
      index: 2,
      name: moduleNames.webStatsOverview,
      hasSubGroups: true,
      displayInLeftNav: true,
      submenu: [
        {
          index: 2.1,

          linkTo: ({ className }) => <Link className={className} to={'/statistics/overview'} />,
          name: tabNames.latestVisitorsOverview,
          displayInLeftNav: true,
        },
        {
          index: 2.2,

          name: tabNames.trafficStructure,
          linkTo: ({ className }) => <Link className={className} to={`/traffic/share`} />,
          displayInLeftNav: true,
          children: [
            {
              name: tabNames.trafficShare,
              linkTo: `/traffic/share`,
              displayInLeftNav: false,
            },
            {
              name: tabNames.trafficSession,
              linkTo: `/traffic/session`,
              displayInLeftNav: false,
            },
            {
              name: tabNames.trafficPagesPerVisit,
              linkTo: `/traffic/visits`,
              displayInLeftNav: false,
            },
            {
              name: tabNames.trafficBounceRate,
              linkTo: `/traffic/bounceRate`,
              displayInLeftNav: false,
            },
          ],
        },
        {
          index: 2.3,
          name: tabNames.latestVisitorsList,

          linkTo: ({ className }) => <Link className={className} to={'/visitors/latest'} />,
          displayInLeftNav: true,
          isNew: true,

          children: [
            {
              name: tabNames.latestVisitorsList,
              linkTo: '/visitors/latest',
              displayInLeftNav: false,
              isNew: true,
            },
            {
              name: tabNames.latestVisitorsMap,
              linkTo: '/visitors/map',
              displayInLeftNav: false,
            },
            {
              name: tabNames.trafficCharts,
              linkTo: '/visitors/charts',
              displayInLeftNav: false,
            },
            {
              name: tabNames.latestVisitorsLocations,
              linkTo: '/visitors/locations',
              displayInLeftNav: false,
            },
          ],
        },
        {
          index: 2.4,
          linkTo: ({ className }) => <Link className={className} to={'/pages/landing'} />,
          name: tabNames.pageVisits,
          displayInLeftNav: true,
          children: [
            {
              name: tabNames.landingPages,
              linkTo: '/pages/landing',
              displayInLeftNav: false,
            },
            {
              name: tabNames.conversions,
              linkTo: '/pages/conversions',
              displayInLeftNav: false,
            },
            {
              name: tabNames.referringSites,
              linkTo: '/pages/referring',
              displayInLeftNav: false,
            },
          ],
        },
        {
          index: 2.5,

          linkTo: ({ className }) => <Link className={className} to={'/competition'} />,
          name: tabNames.competition,
          displayInLeftNav: true,
        },
        {
          index: 2.6,
          linkTo: ({ className }) => <Link className={className} to={'/devices'} />,
          name: tabNames.devices,

          displayInLeftNav: true,
        },
        {
          index: 2.7,
          name: tabNames.campaignsOverview,

          linkTo: ({ className }) => <Link className={className} to={'/campaigns/overview'} />,
          displayInLeftNav: true,
          children: [
            {
              name: tabNames.adsOverview,
              linkTo: '/campaigns/overview',
              displayInLeftNav: false,
            },
            {
              name: tabNames.adsLatestVisitors,
              linkTo: '/campaigns/latest',
              displayInLeftNav: false,
            },
            {
              name: tabNames.myCampaigns,
              linkTo: '/campaigns/my',
              displayInLeftNav: false,
            },
            {
              name: tabNames.adsCharts,
              linkTo: '/campaigns/charts',
              displayInLeftNav: false,
            },
            {
              name: tabNames.utmGenerator,
              linkTo: `/campaigns/utm-generator`,
              displayInLeftNav: false,
            },
          ],
        },
      ],
    },
    {
      index: 3,
      name: tabNames.behaviour,
      displayInLeftNav: true,
      submenu: [
        {
          name: tabNames.eventTracking,
          linkTo: ({ className }) => <Link className={className} to={'/behaviour/event-tracking'} />,
          displayInLeftNav: true,
        },
        {
          name: tabNames.recordings,
          linkTo: ({ className }) => <Link className={className} to={'/behaviour/recordings'} />,
          displayInLeftNav: true,
        },
        {
          name: tabNames.heatmaps,
          linkTo: ({ className }) => <Link className={className} to={'/behaviour/heatmaps'} />,
          displayInLeftNav: true,
        },
        {
          name: tabNames.funnels,
          linkTo: ({ className }) => <Link className={className} to={'/behaviour/funnels'} />,
          displayInLeftNav: true,
        },
      ],
    },
    feedbackTab,
    {
      index: 5,
      name: tabNames.privacyCenter,
      linkTo: ({ className }) => <Link className={className} to={'/settings/privacy-center'} />,
      displayInLeftNav: true,
    },
    {
      index: 6,
      name: tabNames.websiteSettings,
      displayInLeftNav: true,
      submenu: settingsSubTabs,
    },
    milestoneTab,
  ];

  return tabs;
};
